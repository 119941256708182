import React from "react"

import styles from "./index.module.scss"
import { AccountGuard } from "../../../../guards/account.guard"

export default () => (
  <AccountGuard>
    <div className={`${styles.header}`}>
      <h2>Technical Official Introductory</h2>
    </div>

    <div className={`${styles.wrapper}`}>
      <div className={`${styles.sectionHeader}`}>
        <h3>Quiz</h3>
      </div>
    </div>

    <div className={`${styles.contentWrapper}`}>
      <ul className={`${styles.content}`}>
        <li>
          <form>
            <p>
              <ol>
                <li>Name the components of “ASIFF”</li>
              </ol>
            </p>

            <p>
              <span className={`${styles.answer}`}>
                <input name={`question_01`} type="radio" value={`a`} />{" "}
                Achievement, Success, Improve, Functional, Fitness
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_01`} type="radio" value={`b`} />
                Apathy, Sympathy, Ignorance, Fear, Fatigue
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_01`} type="radio" value={`c`} />
                Arrogance, Sympathy, Ignore, Fear, Fatigue
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_01`} type="radio" value={`d`} />
                Annoyance, Systematic, Ignorance, Fullness, Fatigue
              </span>
            </p>

            <p>
              <ol start="2">
                <li>
                  A task or group of tasks, which result in a scored component
                  in a Functional Fitness Competition
                </li>
              </ol>
            </p>

            <p>
              <span className={`${styles.answer}`}>
                <input name={`question_02`} type="radio" value={`wod`} /> WOD
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_02`} type="radio" value={`workout`} />
                Workout
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_02`} type="radio" value={`event`} />
                Event
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_02`} type="radio" value={`test`} />
                Test
              </span>
            </p>

            <p>
              <ol start="3">
                <li>
                  An overall functional fitness competition referred to in its
                  entirety
                </li>
              </ol>
            </p>

            <p>
              <span className={`${styles.answer}`}>
                <input name={`question_03`} type="radio" value={`event`} />{" "}
                Event
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_03`} type="radio" value={`task`} />
                Task
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_03`} type="radio" value={`test`} />
                Test
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_03`} type="radio" value={`season`} />
                Season
              </span>
            </p>

            <p>
              <ol start="4">
                <li>Minimum age in a senior level competition</li>
              </ol>
            </p>

            <p>
              <span className={`${styles.answer}`}>
                <input name={`question_04`} type="radio" value={`21`} /> 21
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_04`} type="radio" value={`16`} />
                16
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_04`} type="radio" value={`18`} />
                18
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_04`} type="radio" value={`40`} />
                40
              </span>
            </p>

            <p>
              <ol start="5">
                <li>
                  A Technical Official should document his / her day by taking a
                  selfie with their favorite athlete.
                </li>
              </ol>
            </p>

            <p>
              <span className={`${styles.answer}`}>
                <input name={`question_05`} type="radio" value={`true`} /> True
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_05`} type="radio" value={`false`} />{" "}
                False
              </span>
            </p>

            <p>
              <ol start="6">
                <li>
                  A Technical Official can rely on the athlete’s briefing to
                  learn the daily expectations at an event.
                </li>
              </ol>
            </p>

            <p>
              <span className={`${styles.answer}`}>
                <input name={`question_06`} type="radio" value={`true`} /> True
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_06`} type="radio" value={`false`} />{" "}
                False
              </span>
            </p>

            <p>
              <ol start="7">
                <li>
                  An athlete can request a review of an opponent’s score if he /
                  she feels there is a disparity in Technical Official
                  performance.
                </li>
              </ol>
            </p>

            <p>
              <span className={`${styles.answer}`}>
                <input name={`question_07`} type="radio" value={`true`} /> True
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_07`} type="radio" value={`false`} />{" "}
                False
              </span>
            </p>

            <p>
              <ol start="8">
                <li>
                  A team can dispute the result of Test 1 after seeing their
                  position on the leaderboard following Test 3.
                </li>
              </ol>
            </p>

            <p>
              <span className={`${styles.answer}`}>
                <input name={`question_08`} type="radio" value={`true`} /> True
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_08`} type="radio" value={`false`} />{" "}
                False
              </span>
            </p>

            <p>
              <ol start="9">
                <li>
                  When an Technical Official’s Inquiry is requested, the outcome
                  may improve or degrade the original score reported.
                </li>
              </ol>
            </p>

            <p>
              <span className={`${styles.answer}`}>
                <input name={`question_09`} type="radio" value={`true`} /> True
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_09`} type="radio" value={`false`} />{" "}
                False
              </span>
            </p>

            <p>
              <ol start="10">
                <li>
                  There are no coaches allowed in the warm up area before an
                  event.
                </li>
              </ol>
            </p>

            <p>
              <span className={`${styles.answer}`}>
                <input name={`question_10`} type="radio" value={`true`} /> True
              </span>
              <span className={`${styles.answer}`}>
                <input name={`question_10`} type="radio" value={`false`} />{" "}
                False
              </span>
            </p>
          </form>
        </li>
      </ul>

      <div className={`${styles.buttons}`}>
        <div className={`${styles.next}`}>
          <a href="/courses/toi/content/quiz">Submit</a>
        </div>
      </div>
    </div>
  </AccountGuard>
)
